import React from 'react'

function PremiumMode() {
  return (
    <div className='ml-5'>
      Premium Mode
    </div>
  )
}

export default PremiumMode
